.adressList>div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.container .element-adress-handle {
    height: 100%;
}

@media (max-width: 620px) {

    .adressList>div {
        display: grid;
        grid-template-columns: 1fr;
    }

    .container .element-adress-handle {
        height: 100%;
        padding: 10px;
    }
}